import React from "react";
import {Helmet} from 'react-helmet'
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout.js"
import './../styles/polecamy.scss'

const AboutPage=() => {

    return (
        <StaticQuery
            query={graphql`
    query allImgQueryAndAllImgQuery {
      source: allFile(filter: { absolutePath: { regex: "/images/polecamy/" } }) {
        edges {
          node {
            childImageSharp {
                fluid {
                ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  `}
            render={data => {
                return (
                    <Layout>
                        <Helmet>
                            <title>Wulkanizacja Tychy, Wymiana opon Tychy - Polecamy</title>

                            <link rel="canonical" href="https://oponytychy.pl/polecamy" />
                            <meta name="description" content="Opony tychy - polecane serwisy" />
                            <meta name="keywords" content="sprzedaż opon Tychy,  Tychy wulkanizacja, przechowywanie opon, przechowalnia opon" />
                        </Helmet>

                        <div className="main recommended">
                            <div className="container_12">
                                <div className="grid_4">
                                    <div className="box1 indents1">
                                        <h2 className="p3-1"><span>Polecamy: </span></h2>
                                        <a className="link" href="https://calculla.pl/" target="_self" rel="noreferrer noopener" aria-label="link do strony zewnętrznej z kalkulatorami">
                                            <Img
                                                fluid={data.source.edges[0].node.childImageSharp.fluid}
                                                alt="Kalkulatory online"
                                            />
                                            <span className="link__name">Kalkulatory online</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="container_12">
                                <div className="grid_4">
                                </div>
                            </div>
                        </div>
                    </Layout>
                )
            }}
        />
    )
}

export default AboutPage
